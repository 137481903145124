<template>
  <el-container style="height: 100%" @dragenter.native="dragenter">
    <el-header style="padding: 0; height: 40px">
      <div id="navigation">设备统计</div>
    </el-header>

    <el-container style="height: calc(100% - 40px)">
      <div style="padding: 20px 20px 0 20px">
        <el-form :inline="true" ref="dataForm" :model="dataForm" @keyup.enter.native="getDataList(true)">
          <el-form-item prop="suit">
            <el-autocomplete
              v-model="dataForm.suit"
              :fetch-suggestions="(a, b) => queryKey(a, b, 'suit')"
              placeholder="产品系列"
              clearable
            ></el-autocomplete>
          </el-form-item>
          <el-form-item prop="model">
            <el-autocomplete
              v-model="dataForm.model"
              :fetch-suggestions="(a, b) => queryKey(a, b, 'model')"
              placeholder="设备型号"
              clearable
            ></el-autocomplete>
          </el-form-item>
          <el-form-item prop="pn">
            <el-autocomplete v-model="dataForm.pn" :fetch-suggestions="(a, b) => queryKey(a, b, 'pn')" placeholder="PN码" clearable></el-autocomplete>
          </el-form-item>
          <el-form-item prop="sn">
            <el-autocomplete v-model="dataForm.sn" :fetch-suggestions="(a, b) => queryKey(a, b, 'sn')" placeholder="SN码" clearable></el-autocomplete>
          </el-form-item>
          <el-form-item prop="name">
            <el-autocomplete
              v-model="dataForm.name"
              :fetch-suggestions="(a, b) => queryKey(a, b, 'name')"
              placeholder="设备名称"
              clearable
            ></el-autocomplete>
          </el-form-item>
          <el-form-item prop="userName">
            <el-autocomplete
              v-model="dataForm.userName"
              :fetch-suggestions="(a, b) => queryKey(a, b, 'user')"
              placeholder="绑定用户"
              clearable
            ></el-autocomplete>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList(true)" type="primary">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="refreshForm()" style="background: rgb(30, 30, 242, 0.1); color: #5053dd">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="exportData()" :loading="downloadLoading">导出</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-main style="padding: 0 20px 20px 20px; overflow: hidden">
        <div style="height: 1px; background: #d4d6d9; opacity: 0.5; margin-bottom: 22px"></div>
        <div class="main">
          <el-table
            v-if="dataList.length"
            :data="dataList"
            v-loading="tabLoding"
            @selection-change="selectionChangeHandle"
            header-cell-class-name="tmc_table_header"
            row-class-name="tmc_table_row"
            style="width: 100%"
            height="calc(100vh - 367px)"
          >
            <!-- <el-table-column type="selection" header-align="center" align="center" width="50"> </el-table-column> -->
            <el-table-column prop="suit" header-align="center" align="center" label="产品系列" width="150"> </el-table-column>
            <el-table-column prop="model" header-align="center" align="center" label="设备型号" width="150"> </el-table-column>
            <el-table-column prop="pn" header-align="center" align="center" label="PN码" width="150"> </el-table-column>
            <el-table-column prop="name" header-align="center" align="center" label="设备名称"> </el-table-column>
            <el-table-column prop="sn" header-align="center" align="center" label="SN码" width="150"> </el-table-column>
            <el-table-column prop="otaVersion" header-align="center" align="center" label="版本固件" width="150"> </el-table-column>
            <el-table-column prop="location" header-align="center" align="center" label="使用范围" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="remark" header-align="center" align="center" show-overflow-tooltip label="备注"> </el-table-column>
            <el-table-column prop="userName" header-align="center" align="center" show-overflow-tooltip label="绑定用户">
              <template slot-scope="scope">
                <el-button type="text" @click="getUserInfo(scope.row.id)">{{ scope.row.userName }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div v-else style="height: 100%; text-align: center">
            <el-image :src="require('@/assets/img/no-data.png')" fit="contain" style="margin: 10% 0 24px 0"> </el-image>
            <div style="font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #73767a">啊哦~这里没有数据呢~</div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <div v-if="dataList.length" style="margin-top: 20px">
          <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </el-footer>
    </el-container>
    <!-- 解绑 -->
    <el-dialog title="用户信息" :visible.sync="dialogVisible" custom-class="tmc-dialog" top="30vh" width="580px">
      <el-descriptions :column="1" :colon="false" :labelStyle="{ width: '64px', 'text-align': 'right' }" style="word-break: break-all">
        <el-descriptions-item label="用户名">{{ userInfo.loginName }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ userInfo.phoneNumber }}</el-descriptions-item>
        <el-descriptions-item label="邮箱地址">{{ userInfo.email }}</el-descriptions-item>
      </el-descriptions>
      <div slot="footer" style="text-align: center">
        <el-button
          type="danger"
          @click="
            checkForm.password = ''
            checkDialogVisible = true
          "
          >解 绑</el-button
        >
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 验证 -->
    <el-dialog title="权限验证" :visible.sync="checkDialogVisible" :close-on-click-modal="false" custom-class="tmc-dialog" top="30vh" width="580px">
      <div style="padding-top: 40px">
        <el-form :model="checkForm" :rules="dataRule" ref="checkForm" :status-icon="false" hide-required-asterisk @keyup.enter.native="toCheck()">
          <el-form-item prop="password">
            <el-input v-model="checkForm.password" :type="passwordType" placeholder="请输入密码以验证管理员权限">
              <div slot="suffix" @click="showPassword = !showPassword" class="password-suffix">
                <i v-if="showPassword" class="el-input__icon el-icon-view"></i>
                <el-image v-else :src="require(`@/assets/img/icon_preview_close.png`)"> </el-image>
              </div>
              ></el-input
            >
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="checkDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toCheck()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改 -->
    <device-add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></device-add-or-update>
  </el-container>
</template>

<script>
import DeviceAddOrUpdate from '@/views/DeviceAddOrUpdate.vue'
export default {
  components: { DeviceAddOrUpdate },
  name: 'Device',
  data() {
    return {
      dataForm: {
        suit: '',
        model: '',
        sn: '',
        pn: '',
        name: '',
        userName: ''
      },
      timeOutId: undefined,
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      downloadLoading: false,
      tabLoding: false,
      dataListSelections: [],
      dataList: [],
      addOrUpdateVisible: false,
      userInfo: {},
      deviceId: '',
      dialogVisible: false,
      checkDialogVisible: false,
      checkForm: {
        password: ''
      },
      dataRule: {
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      },
      showPassword: false
    }
  },
  computed: {
    passwordType() {
      return this.showPassword ? 'text' : 'password'
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    toCheck() {
      this.$refs.checkForm.validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl('/api/v1/user/checkAdmin'),
            method: 'get',
            params: this.$http.adornParams({
              adminPassword: this.checkForm.password
            })
          }).then((res) => {
            if (res && res.code === 0) {
              if (res.data) {
                this.$http({
                  url: this.$http.adornUrl('/api/v1/devices/unbind'),
                  method: 'post',
                  params: this.$http.adornParams({
                    deviceId: this.deviceId
                  })
                }).then((res) => {
                  if (res && res.code === 0) {
                    this.$message({
                      message: '操作成功',
                      type: 'success',
                      duration: 1500,
                      onClose: () => {}
                    })
                    this.dialogVisible = false
                    this.checkDialogVisible = false
                    this.getDataList()
                  } else {
                    this.$message.error(res.msg)
                  }
                })
              } else {
                this.$message.error('密码错误')
              }
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 获取绑定用户信息
    getUserInfo(deviceId) {
      this.deviceId = deviceId
      this.userInfo = {}
      this.$http({
        url: this.$http.adornUrl('/api/v1/devices/deviceUserInfo'),
        method: 'get',
        params: this.$http.adornParams({
          deviceId
        })
      })
        .then((res) => {
          if (res.code === 0) {
            const data = res.data || {}
            this.userInfo = data
            this.dialogVisible = true
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(() => {})
    },
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 导出
    exportData() {
      this.downloadLoading = true
      // 获取数据
      this.$http({
        url: this.$http.adornUrl('/api/v1/devices/statistics'),
        method: 'get',
        params: this.$http.adornParams({
          name: this.dataForm.name,
          suit: this.dataForm.suit,
          model: this.dataForm.model,
          sn: this.dataForm.sn,
          pn: this.dataForm.pn,
          userName: this.dataForm.userName,
          pageNum: 1,
          pageSize: 999999
        })
      })
        .then((res) => {
          if (res.code === 0) {
            const data = res.data
            // 获取当前展示的表格数据
            const list = data.list

            // 懒加载该用法
            import('@/utils/Export2Excel').then((excel) => {
              // 设置导出表格的头部
              const tHeader = ['产品系列', '设备型号', '设备名称', 'SN码', 'PN码', '使用范围', '备注', '绑定用户']
              // 设置要导出的属性
              const filterVal = ['suit', 'model', 'name', 'sn', 'pn', 'location', 'remark', 'userName']

              // 将要导出的数据进行一个过滤
              const data = this.formatJson(filterVal, list)
              // 调用我们封装好的方法进行导出Excel
              excel.export_json_to_excel({
                // 导出的头部
                header: tHeader,
                // 导出的内容
                data,
                // 导出的文件名称
                filename: '设备统计',
                // 导出的表格宽度是否自动
                autoWidth: true,
                // 导出文件的后缀类型
                bookType: 'csv'
              })

              this.downloadLoading = false
            })
          } else {
            this.$message.error(res.msg)
            this.downloadLoading = false
          }
        })
        .catch(() => {
          this.downloadLoading = false
        })
    },
    // 对要导出的内容进行过滤
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j]
        })
      )
    },
    getDataList(flag) {
      if (flag) {
        this.pageIndex = 1
      }
      this.tabLoding = true
      // 设备
      this.$http({
        url: this.$http.adornUrl('/api/v1/devices/statistics'),
        method: 'get',
        params: this.$http.adornParams({
          name: this.dataForm.name,
          suit: this.dataForm.suit,
          model: this.dataForm.model,
          sn: this.dataForm.sn,
          pn: this.dataForm.pn,
          userName: this.dataForm.userName,
          pageNum: this.pageIndex,
          pageSize: this.pageSize
        })
      })
        .then((res) => {
          if (res.code === 0) {
            const data = res.data
            this.dataList = data.list
            this.totalPage = data.total
          } else {
            this.$message.error(res.msg)
          }
          this.tabLoding = false
        })
        .catch(() => {
          this.tabLoding = false
        })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    queryKey(queryString, cb, type) {
      if (this.timeOutId) {
        clearTimeout(this.timeOutId)
      }

      this.timeOutId = setTimeout(() => {
        let results = []
        this.$http({
          url: this.$http.adornUrl(`/api/v1/devices/${type}`),
          method: 'get',
          params: this.$http.adornParams({
            [type]: queryString
          })
        })
          .then((res) => {
            if (res.code === 0) {
              const data = res.data || []
              results = data.map((item) => {
                return { value: item }
              })
            }
            // 调用 callback 返回建议列表的数据
            cb(results)
          })
          .catch(() => {
            cb(results)
          })
      }, 500)
    },
    refreshForm() {
      this.$refs.dataForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  overflow: hidden;
}
#navigation {
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  margin-bottom: 22px;
}
.password-suffix {
  width: 25px;
  height: 100%;
  cursor: pointer;
}
::v-deep .el-image__inner {
  vertical-align: middle;
}
</style>
