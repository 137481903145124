<template>
  <el-dialog title="修改" :close-on-click-modal="false" :visible.sync="visible" custom-class="tmc-dialog">
    <el-form :model="dataForm" ref="dataForm" :rules="dataRule" @keyup.enter.native="dataFormSubmit()" label-width="80px" autocomplete="off">
      <el-form-item label="设备名称" prop="name">
        <el-input
          v-model.trim="dataForm.name"
          placeholder="设备名称"
          maxlength="20"
          :disabled="true"
          :keyup="(dataForm.name = dataForm.name.replace(/\s+/g, ''))"
        ></el-input>
      </el-form-item>
      <el-form-item label="产品系列" prop="suit">
        <el-input v-model="dataForm.suit" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="设备型号" prop="model">
        <el-input v-model="dataForm.model" :disabled="true"></el-input>
      </el-form-item>
      <!-- <el-form-item label="sn" prop="sn">
        <el-input v-model="dataForm.sn" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="pn" prop="pn">
        <el-input v-model="dataForm.pn" :disabled="true"></el-input>
      </el-form-item> -->
      <el-form-item label="使用范围" prop="location">
        <el-input
          v-model.trim="dataForm.location"
          maxlength="20"
          placeholder="使用范围"
          :keyup="(dataForm.location = dataForm.location.replace(/\s+/g, ''))"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="dataForm.remark" placeholder="备注" maxlength="100" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'DeviceAddOrUpdate',
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        name: '',
        suit: '',
        model: '',
        sn: '',
        pn: '',
        location: '',
        remark: ''
      },
      dataRule: {
        name: [{ required: true, message: '设备名称不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    init(id) {
      this.dataForm.id = id
      this.visible = true
      this.$nextTick(() => {
        this.$refs.dataForm.resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/api/v1/devices/get/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then((res) => {
          if (res.code === 0) {
            const data = res.data
            this.dataForm = data
            if (!this.dataForm.location) {
              this.dataForm.location = ''
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/api/v1/devices/${this.dataForm.id}`),
            method: 'PUT',
            data: this.$http.adornData(this.dataForm)
          }).then((res) => {
            if (res && res.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>
